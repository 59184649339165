import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import Nav from 'rsuite/Nav';
import Input from 'rsuite/Input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useRouter } from 'next/router';
import jwtDecode from 'jwt-decode';
import { TOKEN_NAME } from 'utils';
import { useDispatch } from 'react-redux';
import { setUser } from 'redux/actions/userActions';
import { MyLink } from './UserNav';
import styles from './NavbarLoginForm.module.css';

export default function NavbarLoginForm() {
    const router = useRouter();
    const dispatch = useDispatch();
    const formControlStyle = { width: '140px' };
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        enableReinitialize: true,
        onSubmit: async (values, helpers) => {
            if (!values.email || !values.password) {
                return null;
            }
            helpers.setSubmitting(true);
            return axios
                .post(`${process.env.NEXT_PUBLIC_BASE_URL}/auth/login/`, values)
                .then((res) => {
                    let decodedToken = jwtDecode(res.data?.data?.data?.token);
                    localStorage.setItem(TOKEN_NAME, res?.data.data?.data?.token);
                    dispatch(setUser(decodedToken));
                    return (window.location.href = '/');
                })
                .catch((err) => {
                    helpers.setSubmitting(false);
                    if (err?.response?.data?.responsemessage) {
                        return toast.error(err?.response?.data?.responsemessage);
                    }
                    if (err?.message) {
                        return toast.error(err?.message);
                    }
                });
        },
        validationSchema: yup.object().shape({
            email: yup.string().required().label('Email'),
            password: yup.string().required().label('Password'),
        }),
    });

    return (
        <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
            <Nav.Item className="g-color-login g-space">
                <Input
                    placeholder="email"
                    name="email"
                    style={formControlStyle}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                        formik.setFieldValue('email', e);
                    }}
                />
            </Nav.Item>

            <Nav.Item className="g-color-login g-space">
                <Input
                    style={formControlStyle}
                    type="password"
                    name="password"
                    placeholder="Password"
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue('password', e)}
                />
            </Nav.Item>

            <Nav.Item
                className="g-color-white g-space"
                appearance="primary"
                style={{
                    backgroundColor: 'brown',
                    cursor: formik.isSubmitting ? 'progress' : 'pointer',
                }}
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
            >
                {formik.isSubmitting ? 'LOGIN...' : 'LOGIN'}
            </Nav.Item>
            <Nav.Item className="g-color-white g-space" as={MyLink} href="/forgot-password">
                <small>Forgot Password?</small>
            </Nav.Item>
        </form>
    );
}
