import React, { useRef, useState } from 'react';
import Link from 'next/link';
import Navbar from 'rsuite/Navbar';
import Nav from 'rsuite/Nav';
import Button from 'rsuite/Button';
import Image from 'next/image';
import CustomUserAvatar from '../CustomUserAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from '../../redux/actions/userActions';
import NavbarLoginForm from './NavbarLoginForm';
import DropdownMenubar from './DropdownMenubar';
import GamesDrawer from './GamesDrawer';
import Mobilenav from './Mobilenav';
import styles from './UserNavStyles.module.css';
import { handleGetLotteryCatLink, handleSetLotteryCatLink, topNavLinks } from './NavLinks';
import { useRouter } from 'next/router';
import { FaFootballBall } from 'react-icons/fa';

export default function UserNavigation({ onSelect, activeKey, ...props }) {
    const dispatch = useDispatch();
    const router = useRouter();
    let lotteryGameCategory = handleGetLotteryCatLink();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [showMenuDropDown, setShowMenuDropDown] = useState(false);
    const [openGamesDrawer, setOpenGamesDrawer] = useState(false);
    const [activeNavKey, setActiveNavKey] = useState('');
    const { userData, isAuthenticated } = useSelector((state) => state.userData);

    const handleLogoutUser = () => dispatch(logOutUser());
    const toggleDrawer = () => {
        setIsDrawerOpen((prevState) => !prevState);
    };
    const handleCloseGamesDrawer = () => setOpenGamesDrawer(false);
    return (
        <nav style={{ position: 'relative', zIndex: 20 }}>
            <Mobilenav
                toggleDrawer={toggleDrawer}
                isLoggedIn={isAuthenticated}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                user={userData}
            />
            <GamesDrawer open={openGamesDrawer} handleClose={handleCloseGamesDrawer} />
            <Navbar className={styles.navbarFullDiv}>
                <Navbar.Brand href="/" className={styles.desktopBrand}>
                    <Image src="/images/logo1.jpg" width={60} height={40} />
                </Navbar.Brand>
                <div className={styles.mobileTodayGames} onClick={() => setOpenGamesDrawer(true)}>
                    <FaFootballBall className={styles.mobileIcon} />
                    <h6 className={styles.menuTitle}>Today&apos;s Game</h6>
                </div>
                <Navbar.Brand href="/" className={styles.mobileBrand}>
                    <Image src="/images/logo1.jpg" width={50} height={30} />
                </Navbar.Brand>
                <Nav onSelect={onSelect} activeKey={activeKey} className={styles.navLeftSideDiv}>
                    {topNavLinks.map((link, index) => (
                        <Nav.Item
                            href={link.link}
                            as={TopLink}
                            key={link.name}
                            eventKey={link.eventKey}
                            onClick={() => {
                                setActiveNavKey((prevState) => link.eventKey);
                                handleSetLotteryCatLink(link.lotteryCat);
                            }}
                            style={{
                                fontSize: '13.5px',
                                color:
                                    activeNavKey === link.eventKey &&
                                    lotteryGameCategory === link.lotteryCat
                                        ? '#4bee00'
                                        : 'white',
                            }}
                            // className="g-color-white g-space"
                            // className={styles.navLinkStyle}
                        >
                            {link.name}
                        </Nav.Item>
                    ))}
                </Nav>

                <Nav
                    className={
                        isAuthenticated ? styles.navRightSideDiv : styles.navRightSideFullDiv
                    }
                >
                    <div>
                        {isAuthenticated ? (
                            <>
                                <Nav.Item
                                    className="g-color-login g-space"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        position: 'relative',
                                        zIndex: '200',
                                    }}
                                >
                                    <div>
                                        <CustomUserAvatar />
                                    </div>
                                    <div
                                        style={{
                                            minwidth: '100px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <DropdownMenubar
                                            showMenuDropDown={showMenuDropDown}
                                            setShowMenuDropDown={setShowMenuDropDown}
                                            user={userData}
                                        />
                                    </div>
                                </Nav.Item>

                                <Nav.Item
                                    className="g-color-white g-space"
                                    appearance="primary"
                                    style={{ backgroundColor: 'brown' }}
                                    onClick={() => handleLogoutUser()}
                                >
                                    LOGOUT
                                </Nav.Item>
                            </>
                        ) : (
                            <NavbarLoginForm />
                        )}
                    </div>
                </Nav>
                <section className={styles.mobileProfile}>
                    <div>
                        {isAuthenticated ? (
                            <>
                                <div className={styles.mobileProfileCont}>
                                    <CustomUserAvatar />
                                    <small className={styles.mobileHiText}>
                                        Hi, {userData?.firstname}
                                    </small>
                                </div>
                            </>
                        ) : (
                            <div className={styles.mobileForm}>
                                <Button
                                    appearance="primary"
                                    color="yellow"
                                    onClick={() => router.push('/login')}
                                    size="sm"
                                >
                                    Login
                                </Button>
                                <Button
                                    appearance="primary"
                                    color="red"
                                    onClick={() => router.push('/register')}
                                    size="sm"
                                >
                                    Register
                                </Button>
                            </div>
                        )}
                    </div>
                </section>
            </Navbar>

            <ul className={styles.bottomNav}>
                <li className={styles.navBottomLink} onClick={() => router.push('/')}>
                    Home
                </li>
                <li className={styles.navBottomLink} onClick={() => router.push('/contact')}>
                    Contact Us
                </li>
                {isAuthenticated ? null : (
                    <li className={styles.navBottomLink} onClick={() => router.push('/register')}>
                        Join Now
                    </li>
                )}
                <li className={styles.navBottomLink} onClick={() => router.push('/withdrawal')}>
                    Withdrawal
                </li>
                <li className={styles.navBottomLink} onClick={() => router.push('/deposit')}>
                    Deposits
                </li>
                {userData?.isAgent ? null : (
                    <li
                        className={styles.navBottomLink}
                        onClick={() => router.push('/apply-for-bonus')}
                    >
                        Bonuses
                    </li>
                )}
                {/* {userData?.isAgent ? null : (
                    <li
                        className={styles.navBottomLink}
                        onClick={() => router.push('/apply-for-bundle')}
                    >
                        Bundles
                    </li>
                )} */}
            </ul>
        </nav>
    );
}

export const MyLink = React.forwardRef((props, ref) => {
    const { href, as, ...rest } = props;
    return (
        <Link href={href} as={as}>
            <a ref={ref} {...rest} />
        </Link>
    );
});
export const TopLink = React.forwardRef((props, ref) => {
    const { href, as, ...rest } = props;
    return (
        <Link href={href} as={as}>
            <a ref={ref} {...rest} className={styles.navLinkStyle} />
        </Link>
    );
});
