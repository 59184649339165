import React from 'react';
import toast from 'react-hot-toast';
import Dropdown from 'rsuite/Dropdown';
import Popover from 'rsuite/Popover';
import Whisper from 'rsuite/Whisper';
import Button from 'rsuite/Button';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import NumberFormat from 'react-number-format';
import { handleOpenCommDialog } from 'redux/actions/commActions';
import styles from './DopdownMenubar.module.css';
import { ROLES } from 'utils';

const COMMISSION_EVENT_KEY = 'COMMISSION_EVENT_KEY';
const NULL_EVENT_KEY = 'null';
const MenuPopover = React.forwardRef(({ onSelect, ...rest }, ref) => {
    const { userData, walletInformation } = useSelector((state) => state.userData);

    return (
        <Popover ref={ref} {...rest} full>
            <Dropdown.Menu onSelect={onSelect}>
                <Dropdown.Item eventKey={NULL_EVENT_KEY}>
                    <NumberFormat
                        value={Number(walletInformation?.walletBalance)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Wallet Bal: NGN'}
                        renderText={(value, props) => (
                            <span {...props} style={{ cursor: 'default' }}>
                                {value}
                            </span>
                        )}
                    />
                </Dropdown.Item>
                <Dropdown.Item eventKey={COMMISSION_EVENT_KEY}>
                    <NumberFormat
                        value={Number(walletInformation?.commissionBalance)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Comm. Bal: NGN'}
                        renderText={(value, props) => <span {...props}>{value} &rarr;</span>}
                    />
                </Dropdown.Item>
                {!userData?.isAgent && (
                    <Dropdown.Item
                        eventKey={NULL_EVENT_KEY}
                        className={
                            walletInformation?.bonusStatus === 'none'
                                ? styles.noBonus
                                : walletInformation?.bonusStatus === 'pending'
                                ? styles.pendingBonus
                                : styles.bonusActive
                        }
                    >
                        <NumberFormat
                            value={Number(walletInformation?.bonusBalance)}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Bonus Bal: NGN'}
                            renderText={(value, props) => <span {...props}>{value}</span>}
                        />
                    </Dropdown.Item>
                )}
                {!userData?.isAgent && (
                    <Dropdown.Item eventKey={'/my-bundles'}>My Bundles</Dropdown.Item>
                )}
                <Dropdown.Item eventKey={'/profile'}>My Profile</Dropdown.Item>

                {!userData?.isAgent && (
                    <Dropdown.Item eventKey={'/sharefunds'}>Share Funds</Dropdown.Item>
                )}
                {userData?.role === 'cashier' && (
                    <Dropdown.Item eventKey={'/agent-sharefunds'}>Share Funds</Dropdown.Item>
                )}
                <Dropdown.Item eventKey={'/transaction-history'}>Transaction History</Dropdown.Item>
                {!userData?.isAgent && (
                    <Dropdown.Item eventKey={'/sharedfunds-history'}>
                        Shared Funds History
                    </Dropdown.Item>
                )}
                <Dropdown.Item eventKey={'/withdrawal-requests'}>Withdrawal Requests</Dropdown.Item>
                <Dropdown.Item eventKey={'/bet-history'}> Bet History</Dropdown.Item>

                {!userData?.isAgent && (
                    <Dropdown.Item eventKey={'/booked-codes'}> Booked Codes</Dropdown.Item>
                )}
                {userData?.role !== ROLES.PLAYER && userData?.role !== ROLES.INFLUENCER ? (
                    <Dropdown.Item eventKey={'/sent-overdraft'}>Sent Overdraft</Dropdown.Item>
                ) : null}
                {userData?.role !== ROLES.PLAYER && userData?.role !== ROLES.INFLUENCER ? (
                    <Dropdown.Item eventKey={'/received-overdraft'}>
                        Received Overdraft
                    </Dropdown.Item>
                ) : null}
                {userData?.role === ROLES.PLAYER || userData?.role === ROLES.INFLUENCER ? (
                    <Dropdown.Item eventKey={'/affiliates'}>Affiliates </Dropdown.Item>
                ) : null}
                {userData?.isAgent && userData?.role !== 'cashier' && (
                    <Dropdown.Item eventKey={'/downlines'}>Downlines </Dropdown.Item>
                )}
                {userData?.isAgent && userData?.role !== 'cashier' && (
                    <Dropdown.Item eventKey={'/reports'}>Reports </Dropdown.Item>
                )}

                <Dropdown.Item eventKey={'/my-reports'}>My Reports</Dropdown.Item>
                {userData?.isAgent && userData?.role !== 'cashier' && (
                    <Dropdown.Item eventKey={'/downline-reports'}>Downline Reports </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Popover>
    );
});

const DropdownMenubar = () => {
    const ref = React.useRef();
    const router = useRouter();
    const { userData, walletInformation } = useSelector((state) => state.userData);
    const dispatch = useDispatch();

    function handleSelectMenu(eventKey, event) {
        if (eventKey === 'null') {
            return;
        }
        if (eventKey === COMMISSION_EVENT_KEY) {
            return dispatch(handleOpenCommDialog());
        }

        router.push(eventKey);
        ref.current.close();
    }

    const handleCopyReferalLink = async () => {
        // console.log('qqqqqqqqqqqqqqqqqqq', walletInformation);
        navigator.clipboard.writeText(
            window.location.host + '/register/?refc=' + walletInformation?.referralCode,
        );
        toast('Affilliate Link Copied');
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}
        >
            <Whisper
                placement="autoVerticalEnd"
                controlId="control-id-with-dropdown"
                trigger="click"
                ref={ref}
                speaker={<MenuPopover onSelect={handleSelectMenu} />}
            >
                <Button style={{ background: 'transparent', color: 'white' }}>
                    Hi {walletInformation?.firstname}
                </Button>
            </Whisper>
            {!userData?.isAgent && (
                <p
                    onClick={() => handleCopyReferalLink()}
                    style={{ fontSize: '10px', color: 'yellow' }}
                >
                    Copy Referal Link
                </p>
            )}
        </div>
    );
};

export default DropdownMenubar;
