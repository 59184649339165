import { OPEN_COMMISSION_DIALOG, CLOSE_COMMISSION_DIALOG } from '../types/types';

export function handleOpenCommDialog(data) {
    return (dispatch) => {
        dispatch({ type: OPEN_COMMISSION_DIALOG });
    };
}

export function handleCloseCommDialog() {
    return (dispatch) => {
        dispatch({
            type: CLOSE_COMMISSION_DIALOG,
        });
    };
}
