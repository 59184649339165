import React from 'react';
import Link from 'next/link';
import { Navbar, Nav, Drawer } from 'rsuite';
import Image from 'next/image';
import CustomUserAvatar from 'components/CustomUserAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from 'redux/actions/userActions';
import DropdownMenubar from './DropdownMenubar';
import styles from './Mobilenav.module.css';
import toast from 'react-hot-toast';
import { ROLES } from 'utils';

const MyLink = React.forwardRef((props, ref) => {
    const { href, as, ...rest } = props;
    return (
        <Link href={href} as={as}>
            <a ref={ref} {...rest} />
        </Link>
    );
});

function Mobilenav({ toggleDrawer, isLoggedIn, isDrawerOpen, setIsDrawerOpen }) {
    const { userData, walletInformation, isAuthenticated } = useSelector((state) => state.userData);
    const dispatch = useDispatch();
    const handleLogoutUser = () => dispatch(logOutUser());

    const handleCopyReferalLink = async () => {
        // console.log('qqqqqqqqqqqqqqqqqqq', walletInformation);
        navigator.clipboard.writeText(
            window.location.host + '/register/?refc=' + walletInformation?.referralCode,
        );
        toast('Affilliate Link Copied');
    };

    return (
        <>
            <Drawer
                size={'sm'}
                full={true}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                style={{ backgroundColor: 'black', color: 'gray' }}
                backdrop={true}
            >
                {userData?.firstname ? (
                    <Drawer.Body
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'darkgreen',
                        }}
                    >
                        <Navbar.Brand className={styles.userBranding}>
                            <div>
                                <CustomUserAvatar />
                            </div>
                            <div className={styles.userNameDiv}>
                                <p className={styles.userName}>
                                    {userData?.firstname + ' ' + userData?.lastname}
                                </p>

                                {!walletInformation?.isAgent && (
                                    <p
                                        className={styles.referralCodeText}
                                        onClick={() => handleCopyReferalLink()}
                                    >
                                        Copy Referral Code
                                    </p>
                                )}
                            </div>
                        </Navbar.Brand>

                        <Nav vertical>
                            <Nav.Item className={styles.mobileNavItem} as={MyLink} href="/profile">
                                My Profile
                            </Nav.Item>
                            <Nav.Item
                                className={styles.mobileNavItem}
                                as={MyLink}
                                href="/transaction-history"
                            >
                                Transaction History
                            </Nav.Item>
                            {!userData?.isAgent && (
                                <Nav.Item
                                    className={styles.mobileNavItem}
                                    as={MyLink}
                                    href="/sharedfunds-history"
                                >
                                    Shared Funds History
                                </Nav.Item>
                            )}
                            <Nav.Item
                                className={styles.mobileNavItem}
                                as={MyLink}
                                href="/withdrawal-requests"
                            >
                                Withdrawal Requests
                            </Nav.Item>
                            <Nav.Item className={styles.mobileNavItem} as={MyLink} href="/deposit">
                                Deposits
                            </Nav.Item>
                            <Nav.Item
                                className={styles.mobileNavItem}
                                as={MyLink}
                                href="/withdrawal"
                            >
                                Withdrawal
                            </Nav.Item>

                            {!userData?.isAgent && (
                                <Nav.Item
                                    className={styles.mobileNavItem}
                                    as={MyLink}
                                    href="/sharefunds"
                                >
                                    Share Funds
                                </Nav.Item>
                            )}
                            {(userData?.role === ROLES.PLAYER || ROLES.INFLUENCER) && (
                                <Nav.Item
                                    className={styles.mobileNavItem}
                                    as={MyLink}
                                    href="/affiliates"
                                >
                                    Affiliates{' '}
                                </Nav.Item>
                            )}
                            {userData?.role !== 'player' && (
                                <Nav.Item
                                    className={styles.mobileNavItem}
                                    as={MyLink}
                                    href="/sent-overdraft"
                                >
                                    Sent Overdraft
                                </Nav.Item>
                            )}
                            {userData?.role !== 'player' && (
                                <Nav.Item
                                    className={styles.mobileNavItem}
                                    as={MyLink}
                                    href="/received-overdraft"
                                >
                                    Received Overdraft
                                </Nav.Item>
                            )}

                            {userData?.isAgent && (
                                <Nav.Item
                                    className={styles.mobileNavItem}
                                    as={MyLink}
                                    href="/downlines"
                                >
                                    Downlines
                                </Nav.Item>
                            )}

                            {!userData?.isAgent && (
                                <Nav.Item
                                    className={styles.mobileNavItem}
                                    as={MyLink}
                                    href="/booked-codes"
                                >
                                    Booked Codes
                                </Nav.Item>
                            )}
                            <Nav.Item
                                className={styles.mobileNavItem}
                                as={MyLink}
                                href="/bet-history"
                            >
                                Bet History
                            </Nav.Item>
                            <Nav.Item
                                className={styles.mobileNavItem}
                                as={MyLink}
                                href="/apply-for-bonus"
                            >
                                Bonuses
                            </Nav.Item>

                            <br />
                            <Nav.Item
                                appearance="primary"
                                style={{ backgroundColor: 'brown', color: 'white' }}
                                onClick={() => handleLogoutUser()}
                            >
                                LOGOUT
                            </Nav.Item>
                        </Nav>
                    </Drawer.Body>
                ) : (
                    <Drawer.Body
                        style={{
                            color: 'white',
                            backgroundColor: 'darkgreen',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Navbar.Brand href="/" className="g-color-white">
                            <Image src="/images/logo1.jpg" width={60} height={40} />
                        </Navbar.Brand>
                        <br />
                        <Nav vertical>
                            <>
                                <Nav.Item eventKey="1" className={styles.mobileNavItem}>
                                    LOTTO
                                </Nav.Item>
                                <Nav.Item eventKey="2" className={styles.mobileNavItem}>
                                    SPORTS BETTING
                                </Nav.Item>
                                <Nav.Item eventKey="3" className={styles.mobileNavItem}>
                                    VIRTUAL
                                </Nav.Item>
                                <Nav.Item eventKey="4" className={styles.mobileNavItem}>
                                    MAGIC JACKPOT
                                </Nav.Item>
                                <Nav.Item eventKey="5" className={styles.mobileNavItem}>
                                    CASINO
                                </Nav.Item>
                                <Nav.Item eventKey="6" className={styles.mobileNavItem}>
                                    MAGIC LOTTERY
                                </Nav.Item>

                                <Nav.Item
                                    className={styles.mobileNavLoginItem}
                                    as={MyLink}
                                    href="/login"
                                >
                                    LOGIN
                                </Nav.Item>
                                <br />
                                <Nav.Item
                                    className={styles.mobileNavRegisterItem}
                                    as={MyLink}
                                    href="/register"
                                >
                                    I Want to Register
                                </Nav.Item>
                            </>
                        </Nav>
                    </Drawer.Body>
                )}
            </Drawer>
        </>
    );
}

export default Mobilenav;
