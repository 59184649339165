import React from 'react';
import Drawer from 'rsuite/Drawer';
import Placeholder from 'rsuite/Placeholder';
import { formatGameDate, getUserToken } from 'utils';
import { useRouter } from 'next/router';
import styles from './GamesDrawer.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import axios from 'axios';
import useSWR from 'swr';
import TableError from '../shared/TableError/TableError';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { handleSetLotteryCategory } from 'redux/actions/lotteryActions';

const GamesDrawer = ({ open, handleClose }) => {
    const today = dayjs().day();
    const router = useRouter();
    const dispatch = useDispatch();

    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=40&currentWeekDay=${today}`;

    const fetcher = async () => {
        const token = getUserToken();
        const res = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
            },
        });

        return res.data?.data?.data;
    };

    const { data, error } = useSWR(url, fetcher);

    if (error) {
        console.log(error);
        return (
            <Drawer open={open} onClose={handleClose} className={styles.drawerContainer} size="xs">
                <Drawer.Header style={{ paddingLeft: '60px' }}>
                    <Drawer.Title>Today&apos;s Games</Drawer.Title>
                    <div className={styles.btnContainer} onClick={() => handleClose()}>
                        <AiOutlineClose className={styles.btnClose} />
                    </div>{' '}
                </Drawer.Header>
                <Drawer.Body style={{ background: 'black', padding: '5px', paddingLeft: '30px' }}>
                    <br />
                    <TableError color="white" subtitle={error?.message} />
                    <br />
                </Drawer.Body>
            </Drawer>
        );
    }

    if (!data) {
        return (
            <Drawer open={open} onClose={handleClose} className={styles.drawerContainer} size="xs">
                <Drawer.Header style={{ paddingLeft: '60px' }}>
                    <Drawer.Title>Today&apos;s Games</Drawer.Title>
                    <div className={styles.btnContainer} onClick={() => handleClose()}>
                        <AiOutlineClose className={styles.btnClose} />
                    </div>{' '}
                </Drawer.Header>
                <Drawer.Body style={{ background: 'black', padding: '5px', paddingLeft: '30px' }}>
                    <br />
                    <Placeholder.Paragraph active />

                    <br />
                </Drawer.Body>
            </Drawer>
        );
    }

    // console.log('bbbbbbb', game);
    const handleChangeRoute = (data) => {
        dispatch(handleSetLotteryCategory(data?.Lottery?.category));
        router.push(
            `/lottery/${data?.lotteryId}/${data?.Lottery?.slug}?lotteryCategory=${data?.Lottery?.category}&gameId=${data?.gameId}&lotteryName=${data?.lotteryName}`,
        );
        // router.push(
        //     `/?lotteryId=${data?.lotteryId}&lotterySlug=${data?.Lottery?.slug}&lotteryCategory=${data?.Lottery?.category}&gameId=${data?.gameId}&lotteryName=${data?.lotteryName}`,
        // );
        handleClose();
    };
    const handleFormatStartTime = (game) => {
        if (game?.startTime) {
            let date = new Date();
            let newTime = formatGameDate(game?.startTime, true);

            let startTimeArray = [];
            startTimeArray = newTime?.split(':');
            date.setHours(startTimeArray[0]);
            date.setMinutes(startTimeArray[1]);

            return dayjs(date).format('hh:mm a');
        }
    };
    const handleFormatEndTime = (game) => {
        if (game?.endTime) {
            let date = new Date();
            let endTimeArray = [];
            let newTime = formatGameDate(game?.endTime, true);
            endTimeArray = newTime?.split(':');
            date.setHours(endTimeArray[0]);
            date.setMinutes(endTimeArray[1]);

            return dayjs(date).format('hh:mm a');
        }
    };

    return (
        <Drawer open={open} onClose={handleClose} className={styles.drawerContainer} size="xs">
            <Drawer.Header style={{ paddingLeft: '50px' }}>
                <Drawer.Title>Today&apos;s Games</Drawer.Title>
                <div className={styles.btnContainer} onClick={() => handleClose()}>
                    <AiOutlineClose className={styles.btnClose} />
                </div>{' '}
            </Drawer.Header>
            <Drawer.Body style={{ padding: '5px', paddingLeft: '20px' }}>
                <ul className={styles.drawerUl}>
                    {data &&
                        data?.length > 0 &&
                        data?.map((item, index) => (
                            <li className={styles.drawerLi} key={item?.gameId}>
                                <div className={styles.gameName}>
                                    <p onClick={() => handleChangeRoute(item)}>{item?.name}</p>
                                </div>
                                <div className={styles.startTime}>
                                    {handleFormatStartTime(item)}
                                </div>
                                <div className={styles.endTime}>{handleFormatEndTime(item)}</div>
                            </li>
                        ))}
                </ul>
            </Drawer.Body>
        </Drawer>
    );
};

export default GamesDrawer;
