import React from 'react';
import useSWR from 'swr';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Avatar } from 'rsuite';
import { checkExpiredUserToken, getUserToken } from '../../utils';

const CustomUserAvatar = () => {
    // const user = useSelector((state) => state?.user?.user);
    const { userData } = useSelector((state) => state?.userData);
    const url = userData?.isAgent
        ? `${process.env.NEXT_PUBLIC_BASE_URL}/agent/fetch-agent/${userData?.userId}`
        : `${process.env.NEXT_PUBLIC_BASE_URL}/user/fetch-user/${userData?.userId}`;

    const defaultUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/fetch-default-avatar`;
    const avartarStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'blue',
        color: 'white',
        width: '33px',
        height: '33px',
        borderRadius: '50%',
        margin: '3px',
        textTransform: 'uppercase',
        fontSize: '16px',
    };
    const fetcher = async () => {
        const token = getUserToken();
        const res = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
            },
        });

        return res.data?.data?.data;
    };

    const fetchDefaultAvatar = async () => {
        const token = getUserToken();

        const defaultRes = await axios.get(defaultUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
            },
        });

        return defaultRes.data?.data?.data;
    };

    const { data: defaultAvatarData, error: defaultAvatarError } = useSWR(
        defaultUrl,
        fetchDefaultAvatar,
    );

    const { data, error } = useSWR(url, fetcher);

    if (error) {
        return <div style={avartarStyles}>B</div>;
    }

    if (!data) {
        return <div style={avartarStyles}>B</div>;
    }

    if (data?.avatarUrl) {
        return (
            <Avatar
                style={{ width: '35px', height: '35px' }}
                circle
                src={data?.avatarUrl}
                alt={data?.firstname}
            />
        );
    }
    if (defaultAvatarData?.avatarUrl) {
        return (
            <Avatar
                style={{ width: '35px', height: '35px' }}
                circle
                src={defaultAvatarData?.avatarUrl}
                alt={data?.firstname}
            />
        );
    }
    return <div style={avartarStyles}>{data?.firstname[0]}</div>;
};

export default CustomUserAvatar;
