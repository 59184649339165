import {
    AFRIKENO,
    EAZY_WIN,
    FIVE_0F_NINETY,
    LEGENDARY_LOTTO,
    MEGA_7,
    SALARY_FOR_LIFE,
} from 'utils';

const NO_CAT = 'NO_CAT';
export const LINK_CATEGORY = 'LINK_CATEGORY';
export const topNavLinks = [
    { name: 'Lotto', link: '/', eventKey: 1, lotteryCat: NO_CAT },
    { name: 'Sports Betting', link: '/sports-betting', eventKey: 2, lotteryCat: NO_CAT },
    { name: 'Keno', link: `/play-lottery`, eventKey: 3, lotteryCat: AFRIKENO },
    { name: 'Eazy-Win', link: `/play-lottery`, eventKey: 4, lotteryCat: EAZY_WIN },
    { name: 'Legendary Lotto', link: `/play-lottery`, eventKey: 5, lotteryCat: LEGENDARY_LOTTO },
    { name: '5/90-Lotto', link: `/play-lottery`, eventKey: 6, lotteryCat: FIVE_0F_NINETY },
    { name: 'Mega 7', link: `/play-lottery`, eventKey: 7, lotteryCat: MEGA_7 },
    { name: 'Salary-4Life', link: `/play-lottery`, eventKey: 8, lotteryCat: SALARY_FOR_LIFE },
    { name: 'Raffle Draw', link: `/raffle-draw`, eventKey: 9, lotteryCat: NO_CAT },
    {
        name: 'Virtual Fantasy Game',
        link: `/virtual-fantasy-game`,
        eventKey: 10,
        lotteryCat: NO_CAT,
    },
    { name: 'MLV Soccer Game', link: `/mlv-soccer-game/`, eventKey: 11, lotteryCat: NO_CAT },
];

export const handleSetLotteryCatLink = (cat) => {
    if (typeof window === 'object') {
        if (cat === NO_CAT) {
            return localStorage.removeItem(LINK_CATEGORY);
        }
        localStorage.setItem(LINK_CATEGORY, cat);
    }
};

export const handleGetLotteryCatLink = () => {
    let lotteryCategory = '';
    if (typeof window === 'object') {
        lotteryCategory = localStorage.getItem(LINK_CATEGORY);
    }
    return lotteryCategory;
};
